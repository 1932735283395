import { Controller } from "stimulus";

export default class extends Controller {
    static targets = ["form"];

    private formTarget: HTMLFormElement;
    private hasFormTarget: boolean;
    private dismissed = false;

    connect() {
        if (this.automaticRedirect) {
            setTimeout(this.redirect.bind(this), this.delay);
        }
    }

    disconnect() {
        this.dismissed = true;
    }

    skip() {
        localStorage.setItem("strings-platform-redirect", "true");
        this.redirect();
    }

    redirect() {
        if (!this.dismissed) {
            if (this.hasFormTarget) {
                this.formTarget.submit();
            } else {
                window.location.href = this.redirectTo;
            }
        }
    }

    private get redirectTo() {
        return this.data.get("to");
    }

    private get automaticRedirect() {
        return this.data.has("auto");
    }

    private get instantRedirect() {
        return localStorage.getItem("strings-platform-redirect") === "true";
    }

    private get delay(): number {
        if (this.instantRedirect) {
            return 0;
        } else {
        // 5 seconds default delay
            return parseInt(this.data.get("delay")) || 5000;
        }
    }
}
